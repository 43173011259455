'use strict';

import React, { Component } from 'react';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_6']} flex-col indexResponse_box_6__2xis9`}>
        <div className={` flex-col  indexResponse_section_usa__2zolh`} />
        <span className={`${styles['text_13']} bold`}>留学美国的优势</span>
        <div className="dv_mainbody_wrap_box">
          <span className={`${styles['paragraph_1']} block text_bottom_24 heightAuto`}>
            美国是国际学生的首选留学目的地，具有卓越的学术水平和文化多样性。
          </span>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>多样化而灵活的教育</span><span className={`${styles['paragraph_1']}`}>——通过结合通识课程和特定领域课程提供定制化学位。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>技术投资</span><span className={`${styles['paragraph_1']}`}>——获得最新的技术和资源。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>卓越的学术和研究</span><span className={`${styles['paragraph_1']}`}>——你的课程将是理论和实践研究的结合。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>实习机会</span><span className={`${styles['paragraph_1']}`}>——充分利用OPT和CPT机会让你为进入职场做好准备。</span>
          </div>


          <div>
            <span className={`${styles['text_14']} bold block text_bottom_24`}>体验新文化</span>
          </div>

          <span className={`${styles['paragraph_1']} text_bottom_24 block heightAuto`}>
            美国幅员辽阔，文化多元，使其成为国际学生的天然熔炉。生活在这里，你将融入一个多元而热情的社区，并有机会探索新文化，还能结交世界各地的朋友。
          </span>

          <div className='dv_text_li_wrap text_bottom_24'>
            <ul>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}>从东海岸充满活力的城市到西海岸悠闲的氛围，你可以通过多种方式体验美国的多样性。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>娱乐、风景和美食使美国成为了绝佳的旅游目的地。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>通过博物馆、标志性建筑和著名城市探索多元文化的历史。</span></div>
              </li>
            </ul>
          </div>

          <div className='dv_card_box_content flex-row text_bottom_28'>
            <div className='dv_leftimg dv_imglogo-3'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>适应海外生活</h4>
              <span className='text_photogry text_bottom_24'>留学海外是你生活转变的开始。让你更加轻松地过渡到英国的生活。</span>
              <div><a className='clickable dv_wenxin_button'>温馨提示</a></div>
            </div>
          </div>

          <div className='dv_card_box_content flex-row text_bottom_28'>
            <div className='dv_leftimg dv_imglogo-2'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>学习一种全球语言</h4>
              <span className='text_photogry text_bottom_24'>勇往直前，脱颖而出。精通的英语技能将助力你的未来职业发展。</span>
              <div><a className='clickable dv_wenxin_button'>了解更多</a></div>
            </div>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block`}>尽享奇妙之旅</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>旅行能使你的世界观开阔，让你接触到不同的文化和生活方式。享受美国为你带来的丰富多样性。</span>
          <div className='dv_text_li_wrap text_bottom_24 dv_small_dot'>
            <ul>
              <li >
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>探索东海岸充满活力的城市，这里的能量极富感染力，拥有丰富的历史和精彩的娱乐生活。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>探索西海岸的海滩、山脉和沙漠。这里以其美丽的风景和悠闲的生活方式而闻名。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>了解中西部地区，以及这里的标志性城市和友好的人民。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>爱上热情的南方，这里以生机勃勃的文化、魅力和美食而闻名。</span></div>
              </li>

            </ul>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block widthAuto`}>为你的未来投资</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>美国行业众多，教育享誉世界。 无论你打算毕业后在美国工作还是返回家乡，美国学位都将拓展你的视野，增加你的机会。在这里学习，你也会学到宝贵的生活技能。</span>

          <div className='dv_text_li_wrap text_bottom_24 dv_small_dot'>
            <ul>
              <li >
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>在拥有尖端技术的研究所学习。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>通过CPT、OPT和实习机会体验职场生活。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>在世界知名研究机构向相关领域的领导者学习。</span></div>
              </li>
            </ul>
          </div>

          <div className='dv_card_box_content flex-row  text_bottom_v29'>
            <div className='dv_leftimg dv_imglogo-2'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>智慧教育助力你的成功</h4>
              <span className='text_photogry text_bottom_24'>从分析师到工程师，我们的学生已经在世界各地开启了成功的职业生涯。</span>
              <div><a className='clickable dv_wenxin_button'>认识我们的校友</a></div>
            </div>
          </div>
          <span className={`${styles['text_14']} bold text_bottom_24 block widthAuto`}>现在开启旅程 改变你的生活</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>对出国留学的想法感到激动吗？迈向国际教育的下一步，看看智慧教育可以带你去向何方！</span>

          <div className='dv_search_universty_wrap flex-col justify-between text_bottom_v29'>
            <h3 className='text_middle_title bold text_search_title'>搜索大学或课程</h3>
            <div className='flex-row justify-center'>
              <a className='clickable dv_wenxin_button'>搜索课程</a>
              <div className='dv_split_20'></div>
              <a className='clickable dv_wenxin_button'>搜索大学</a>
            </div>
          </div>

        </div>


      </div >
    );
  }
}
export default DDS_lanhupage_0;
