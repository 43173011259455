'use strict';

import React, { Component } from 'react';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_6']} flex-col indexResponse_box_6__2xis9`}>
        <div className={` flex-col  indexResponse_section_language__2zolh`} />
        <span className={`${styles['text_13']} bold`}>学习英语</span>
        <div className="dv_mainbody_wrap_box">
          <span className={`${styles['paragraph_1']} block text_bottom_24 heightAuto`}>
            作为50多个国家/地区通用的语言，英语在全球范围内使用甚广，为你打开大门，拥抱无限机会。<br></br>
            英语让你能够探索多个旅行目的地，并有可能助推你的职业前景。
          </span>



          <div>
            <span className={`${styles['text_14']} bold block text_bottom_24 widthAuto`}>学习一门新语言的三大技巧</span>
          </div>



          <div className='dv_no_dot text_bottom_24'>
            <ul>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>1.多读多看：</strong>不论是你最喜爱的小说还是在线文章，坚持英文阅读习惯能帮助培养你的语感。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>2.锻炼口语：</strong>从简单的开始。询问方向、在餐厅点餐、练习与朋友对话等。只要坚持下去，你会发现自己的口语在以惊人的速度进步。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>3.观看娱乐节目：</strong>众多娱乐节目供你选择。比如电影，挑一部自己喜欢的英语电影，对照字幕来观看。</span>
                </span>
                </div>
              </li>
            </ul>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block`}>现在开启你的旅程</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>对出国留学的想法感到激动吗？迈向国际教育的下一步，看看智慧教育可以带你去向何方！</span>

          <div className='dv_card_box_content flex-row text_bottom_28'>
            <div className='dv_leftimg dv_imglogo-4'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>寻找适合你的大学</h4>
              <span className='text_photogry text_bottom_24'>从英国、美国或澳大利亚选择适合你的大学。</span>
              <div><a className='clickable dv_wenxin_button'>搜索大学</a></div>
            </div>
          </div>

          <div className='dv_card_box_content flex-row text_bottom_v29'>
            <div className='dv_leftimg dv_imglogo-5'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>寻找适合你的课程</h4>
              <span className='text_photogry text_bottom_24'>专为你量身定制的本科和研究生课程或英语语言课程。</span>
              <div><a className='clickable dv_wenxin_button'>搜索课程</a></div>
            </div>
          </div>








        </div>


      </div >
    );
  }
}
export default DDS_lanhupage_0;
