'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_3 extends Component {
  state = {};
  setValue(val) { }
  onNextSlide = () => {
    //var i = this.state.currentSlideIndex + 1;
    //this.props.onClickNextcurrentSLide(i)
    document.getElementById('mainwrap').scrollBy({
      top: 20, // 垂直滚动500px
      left: 0, // 水平位置（这里不改变）
      behavior: 'smooth' // 平滑滚动效果
    });
  }
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_22']} flex-row indexResponse_box_22__1PAuR`}>
          <div className={`${styles['block_8']} flex-col justify-between`}>
            <div className={`${styles['group_11']} flex-row indexResponse_group_11__1IbSa `}>
              <span className={`${styles['text_11']} indexResponse_text_11__1Qle_`}>国内一年（预科+大一）、海外大学二年——完成本科学习，获教育部认证的学士学位</span>
              <div className={`clickable ${styles['box_18']} flex-row hidden`}>
                <span className={`${styles['text_12']}`}>查看详情</span>
                <img
                  className={`${styles['label_2']}`}
                  src={
                    require('./assets/img/SketchPngd5cc31c7e94b2414b3af4c585037852c61505118557607dec9e414b31c008970.png')
                  }
                />
              </div>
              <span className={`${styles['text_3']} indexResponse_text_3__32nG-`}>Multi-Agent&nbsp;System</span>
            </div>
            <div className={`${styles['block_2']} flex-col indexResponse_block_2__1yJgF`}>
              <div className={`${styles['box_4']} flex-col`} />
              <div className={`${styles['box_5']} flex-col`} />
              <div className={`${styles['box_6']} flex-col`} />
              <span className={`${styles['text_4']}`}>1+2定向本科</span>
              <div className={`${styles['box_7']} flex-col`} />
              <div className={`${styles['box_8']} flex-col`} />
              <img
                className={`${styles['image_4']} indexResponse_image_4__3MqGs`}
                src={
                  require('./assets/img/SketchPng2a8675b81e27764903f1304c221689ced87ea3dbbfa3996be807f85d83d596d3.png')
                }
              />
              <div className={`${styles['section_1']} flex-row justify-between indexResponse_section_1__2gaww animate_slide`}>
                <span className={`${styles['text_13']} indexResponse_text_13__1cRiF`}>时间节点</span>
                <div className={`${styles['group_12']} flex-row justify-between indexResponse_group_12__JsAyW`}>
                  <div className={`${styles['text-group_4']} flex-col`}>
                    <div className={`${styles['text-wrapper_1']}`}>
                      <span className={`${styles['text_14']}`}>8-12</span>
                      <span className={`${styles['text_15']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_16']}`}>预科就读</span>
                  </div>
                  <div className={`${styles['text-group_5']} flex-col`}>
                    <div className={`${styles['text-wrapper_2']}`}>
                      <span className={`${styles['text_17']}`}>次年</span>
                      <span className={`${styles['text_18']}`}>1-7</span>
                      <span className={`${styles['text_19']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_20']}`}>在国内就读普利茅斯艺术大学读大一课程</span>
                  </div>
                  <div className={`${styles['text-group_6']} flex-col`}>
                    <div className={`${styles['text-wrapper_3']}`}>
                      <span className={`${styles['text_21']}`}>次年</span>
                      <span className={`${styles['text_22']}`}>9</span>
                      <span className={`${styles['text_23']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_24']}`}>赴英国读大二大三课程，完成本科学习</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['block_9']} flex-col`}>
            <div className={`${styles['box_1']} flex-col indexResponse_box_1__3EMJL`}>
              <span className={`${styles['text_25']} indexResponse_text_25__9kteb`}>1+2&nbsp;定向本科</span>
            </div>
            <div className={`${styles['box_3']} flex-col indexResponse_box_3__1tU-N indexResponse_box_1__YHdN8`}>
              <div className={`${styles['group_3']} flex-col indexResponse_group_3__30tu0`}>
                <div className={`${styles['group_4']} flex-col`} />
              </div>
              <img
                className={`clickable ${styles['image_1']} indexResponse_image_1__2f7wk `}
                onClick={() => window.open("http://ai.imasedu.com", '_blank')}
                src={
                  require('./assets/img/SketchPng4420ce05fbeb75905a3c3c0691d5031b4627da5c16d38d4503a7de6d9fb91413.png')
                }
              />
            </div>
            <img
              className={`clickable ${styles['image_2']}`}
              onClick={() => this.onNextSlide()}
              src={
                require('./assets/img/SketchPngd08825a154ca581054fc75372f7edca364d9ed279fa43b889c445ea09526f894.png')
              }
            />
            <img
              className={`clickable ${styles['image_3']}`}

              src={
                require('./assets/img/SketchPng1043bd5c5c67398c345b9723acb5e8d9485cafdf0c89eca728c1f52603b44a47.png')
              }
            />
          </div>
        </div>
        <div className={`${styles['box_20']} flex-col indexResponse_box_20__1VkIY fade-out`}>
          <span className={`${styles['text_26']} indexResponse_text_26__37p-V`}>项目优势：</span>
          <div className={`${styles['image-text_8']} flex-row justify-between`}>
            <div className={`${styles['section_2']} flex-col indexResponse_section_2__233H5`} />
            <span className={`${styles['text-group_7']} indexResponse_text-group_7__1mHXq`}>六大艺术设计＆艺术商管专业&nbsp;适合艺术生与非艺术生就读</span>
          </div>
          <div className={`${styles['image-text_9']} flex-row justify-between indexResponse_image-text_9__1oouy`}>
            <div className={`${styles['box_21']} flex-col indexResponse_box_21__anOYa`} />
            <span className={`${styles['text-group_8']} indexResponse_text-group_8__34PWt`}>提前录取，入学即可获得海外大学&nbsp;offer</span>
          </div>
          <div className={`${styles['image-text_10']} flex-row justify-between indexResponse_image-text_10__fUEYL`}>
            <div className={`${styles['section_3']} flex-col indexResponse_section_3__2pGJz`} />
            <span className={`${styles['text-group_9']} indexResponse_text-group_9__2KVVk`}>海外大学教授授课，交大老师全程辅导，帮助学生取得学分</span>
          </div>
          <div className={`${styles['image-text_11']} flex-row justify-between indexResponse_image-text_11__1C2XF`}>
            <div className={`${styles['block_6']} flex-col indexResponse_block_6__2MkZ8`} />
            <span className={`${styles['text-group_10']} indexResponse_text-group_10__1BJDK`}>完成大一课程再出国，安全且性价比高</span>
          </div>
          <div className={`${styles['image-text_12']} flex-row justify-between indexResponse_image-text_12__3FibI`}>
            <div className={`${styles['block_7']} flex-col indexResponse_block_7__10xz0`} />
            <span className={`${styles['text-group_11']} indexResponse_text-group_11__1E-4s`}>在国内提前适应英国大学教学模式</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_3;
