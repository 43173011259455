'use strict';

import React, { Component } from 'react';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  onNextSlide = () => {
    //var i = this.state.currentSlideIndex + 1;
    //this.props.onClickNextcurrentSLide(i)
    document.getElementById('mainwrap').scrollBy({
      top: 20, // 垂直滚动500px
      left: 0, // 水平位置（这里不改变）
      behavior: 'smooth' // 平滑滚动效果
    });
  }
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['group_25']} flex-col fade-out indexResponse_group_25__1ReLw`}>
          <div className={`${styles['group_28']} flex-col`}>
            <div className={`${styles['text-wrapper_24']} flex-row justify-between indexResponse_text-wrapper_24__MDwfc`}

            >
              <span className={`${styles['paragraph_1']} indexResponse_paragraph_1__26vm9 slide-left`}
              >
                1+3
                <br />
                艺术预科
              </span>
              <span className={`${styles['text_4']} indexResponse_text_4__1_Xnh`}>Multi-Agent&nbsp;System</span>
            </div>
            <div className={`${styles['section_10']} flex-row`}>
              <div className={`${styles['group_16']} flex-col`} />
            </div>
            <div className={`${styles['section_11']} flex-row justify-between indexResponse_section_11__1lMfn`}>
              <span className={`${styles['text_11']} indexResponse_text_11__2RuIl`}>国内一年预科、海外大学三年——完成本科学习，获教育部认证的学士学位</span>
              <div className={`${styles['section_12']} flex-col justify-between indexResponse_section_12__209Gs`}>
                <div className={`${styles['group_6']} flex-col`} />
                <div className={`${styles['section_2']} flex-col`} />
              </div>
            </div>
            <div className={`${styles['text-wrapper_25']} flex-row`}>
              <span className={`${styles['text_3']}`}>1+3艺术预科</span>
            </div>
            <div className={`${styles['section_13']} flex-row justify-between indexResponse_section_13__b6Ts0`}>
              <div className={`clickable ${styles['box_2']} flex-row hidden`}>
                <div className={`${styles['image-text_8']} flex-row justify-between `}>
                  <span className={`${styles['text-group_1']}`}>查看详情</span>
                  <img
                    className={`${styles['label_1']}`}
                    src={
                      require('./assets/img/SketchPngd5cc31c7e94b2414b3af4c585037852c61505118557607dec9e414b31c008970.png')
                    }
                  />
                </div>
              </div>
              <div className={`${styles['section_14']} flex-col justify-between indexResponse_section_14__2OQEx`}>
                <div className={`${styles['box_3']} flex-col`} />
                <div className={`${styles['group_6']} flex-col`} />
                <div className={`${styles['group_6']} flex-col`} />

              </div>
            </div>

            <div className={`${styles['section_16']} flex-row indexResponse_section_16__3H9PG`}>
              <div className={`${styles['section_17']} flex-col justify-between`}>
                <div className={`${styles['box_1']} flex-col indexResponse_box_1__YHdN8 `}>
                  <div className={`${styles['group_2']} flex-col indexResponse_group_2__2ni8Y`}>
                    <div className={`${styles['group_3']} flex-col indexResponse_group_3__1w3uk`} />
                  </div>
                  <img
                    className={`clickable ${styles['image_1']} indexResponse_image_1__2f7wk`}
                    onClick={() => window.open("http://ai.imasedu.com", '_blank')}
                    src={
                      require('./assets/img/SketchPng4420ce05fbeb75905a3c3c0691d5031b4627da5c16d38d4503a7de6d9fb91413.png')
                    }
                  />
                </div>
                <img
                  className={`clickable ${styles['image_2']}`}
                  onClick={() => this.onNextSlide()}
                  src={
                    require('./assets/img/SketchPngd08825a154ca581054fc75372f7edca364d9ed279fa43b889c445ea09526f894.png')
                  }
                />
                <img
                  className={`clickable ${styles['image_3']}`}

                  src={
                    require('./assets/img/SketchPng1043bd5c5c67398c345b9723acb5e8d9485cafdf0c89eca728c1f52603b44a47.png')
                  }
                />
              </div>
              <div className={`${styles['block_10']} flex-row justify-between indexResponse_block_10__39Zsz slide-right`}>
                <span className={`${styles['text_12']} indexResponse_text_12__2SDqp`}>时间节点</span>
                <div className={`${styles['box_21']} flex-row justify-between indexResponse_box_21__22SLV`}>
                  <div className={`${styles['text-group_2']} flex-col`}>
                    <div className={`${styles['text-wrapper_9']}`}>
                      <span className={`${styles['text_13']}`}>8</span>
                      <span className={`${styles['text_14']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_15']}`}>开心迎新、适应校园生活及学习模式。</span>
                  </div>
                  <div className={`${styles['text-group_3']} flex-col`}>
                    <div className={`${styles['text-wrapper_10']}`}>
                      <span className={`${styles['text_16']}`}>12</span>
                      <span className={`${styles['text_17']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_18']}`}>确定申请的学校及专业、了解海外大学申请信息、结合校内成绩定校。</span>
                  </div>
                  <div className={`${styles['text-group_4']} flex-col`}>
                    <div className={`${styles['text-wrapper_11']}`}>
                      <span className={`${styles['text_19']}`}>次年</span>
                      <span className={`${styles['text_20']}`}>3</span>
                      <span className={`${styles['text_21']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_22']}`}>安排雅思考试、完成海外大学申请。</span>
                  </div>
                  <div className={`${styles['text-group_5']} flex-col`}>
                    <div className={`${styles['text-wrapper_12']}`}>
                      <span className={`${styles['text_23']}`}>次年</span>
                      <span className={`${styles['text_24']}`}>4</span>
                      <span className={`${styles['text_25']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_26']}`}>期末考试、宿舍预定、参加大学行前会。</span>
                  </div>
                  <div className={`${styles['group_17']} flex-col`}>
                    <div className={`${styles['box_22']} flex-row`}>
                      <div className={`${styles['text-wrapper_13']}`}>
                        <span className={`${styles['text_27']}`}>次年</span>
                        <span className={`${styles['text_28']}`}>5</span>
                        <span className={`${styles['text_29']}`}>&nbsp;月</span>
                      </div>
                    </div>
                    <div className={`${styles['text-wrapper_26']} flex-row indexResponse_text-wrapper_26__2qGaa`}>
                      <span className={`${styles['text_30']}`}>预科结业</span>
                    </div>
                  </div>
                  <div className={`${styles['text-group_6']} flex-col`}>
                    <div className={`${styles['text-wrapper_15']}`}>
                      <span className={`${styles['text_31']}`}>次年</span>
                      <span className={`${styles['text_32']}`}>6</span>
                      <span className={`${styles['text_33']}`}>&nbsp;月</span>
                    </div>
                    <span className={`${styles['text_34']}`}>出国前准备，办理签证。</span>
                  </div>
                </div>
              </div>

              <img
                className={`${styles['image_4']} indexResponse_image_4__Ncc3I`}
                src={
                  require('./assets/img/SketchPng3a7bcacf312f1abafa7ec049b4b1fa4c6dc8e9f13cd4ec8e975caf18fc1a23f0.png')
                }
              />

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
