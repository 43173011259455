'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_Footer extends Component {
  state = {};
  setValue(val) { }

  returnTop = () => {
    if (document.getElementById('mainwrap')) {
      document.getElementById('mainwrap').scrollTo({
        top: 0, // 垂直滚动500px
        left: 0, // 水平位置（这里不改变）
        behavior: 'smooth' // 平滑滚动效果
      });
    } else {
      window.scrollTo({
        top: 0, // 垂直滚动500px
        left: 0, // 水平位置（这里不改变）
        behavior: 'smooth' // 平滑滚动效果
      });
    }

  }
  render() {
    return (
      <div className={`${styles['section_5']} flex-col indexResponse_section_5__1POZ-`} id="footer">
        <div className={`${styles['group_10']} flex-row indexResponse_group_10__23AtB`}>
          <img
            className={`${styles['image_4']} clickable `}
            onClick={() => this.returnTop()}
            src={
              require('./assets/img/SketchPng0e68dd3406b3f0556c1e31a159c117b7cb297c0afa5441eb14f084a3332e0740.png')
            }
          />
          <div className={`${styles['box_21']} flex-col `}>
            <div className={`${styles['text-group_25']} flex-col indexResponse_text-group_25__1avoi`}>
              <span className={`${styles['text_25']}`}>联系我们</span>
              <span className={`${styles['text_26']}`}>电话：021-62932920（非学历教育咨询）52385348（学历教育收尾咨询）</span>
            </div>
            <span className={`${styles['text_27']} widthAuto`}>
              邮箱：lec&#64;sjtu.edu.cn(院长信箱)&nbsp;nec&#64;sjtu.edu.cn（学历教育收尾咨询)
            </span>
            <span className={`${styles['text_28']}`}>地址：上海市长宁区法华镇路535号</span>
          </div>
          <div className={`${styles['box_22']} flex-col `}>
            <span className={`${styles['text_29']}`}>关注我们</span>
            <div className={`${styles['image-text_37']} flex-col align-center`}>
              <div className={`${styles['section_6']} flex-col`} />
              <span className={`${styles['text-group_13']}`}>微信号</span>
            </div>
          </div>
          <div className={`${styles['image-text_38']} flex-col  align-center`}>
            <div className={`${styles['section_7']} flex-col`} />
            <span className={`${styles['text-group_14']}`}>校友号</span>
          </div>
          <div className={`${styles['image-text_39']} flex-col  align-center`}>
            <div className={`${styles['block_8']} flex-col`} />
            <span className={`${styles['text-group_15']}`}>视频号</span>
          </div>
        </div>

        <img
          className={`${styles['image_5']}`}
          src={
            require('./assets/img/SketchPngd7b4ab36e5342a6b5578205c5aa31ac227c9ae9eec1377f71b45b7fb3b0bfc37.png')
          }
        />
        <span className={`${styles['text_30']} indexResponse_text_30__1GEkt`}>版权所有©上海交通大学终身教育学院&nbsp;沪交ICP备20210307</span>
        <div className={`${styles['box_12']} flex-col indexResponse_box_12__29QMj`}>
          <div className={`${styles['block_9']} flex-col indexResponse_block_9__21K3g`}>
            <div className={`${styles['box_13']} flex-col`} />
          </div>
          <img
            className={`${styles['image_6']} indexResponse_image_6__14Ygl clickable`}
            onClick={() => window.open("http://ai.imasedu.com", '_blank')}
            src={
              require('./assets/img/SketchPng4420ce05fbeb75905a3c3c0691d5031b4627da5c16d38d4503a7de6d9fb91413.png')
            }
          />
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_Footer;
