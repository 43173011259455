'use strict';

import React, { Component } from 'react';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_6']} flex-col indexResponse_box_6__2xis9`}>
        <div className={` flex-col  indexResponse_section_culture__2zolh`} />
        <span className={`${styles['text_13']} bold`}>适应海外生活</span>
        <div className="dv_mainbody_wrap_box">
          <span className={`${styles['paragraph_1']} block text_bottom_24 heightAuto`}>
            出国留学将为你开启令人兴奋的旅程。你会结识新朋友，体验另一个国家的生活。但是你或许也会想念熟悉的家乡生活。别担心，有这种感觉的并非你一个人。<br></br>
            英国、美国和澳大利亚都是多元化的国家，欢迎来自不同信仰和文化的人们。给自己一些时间来适应新文化，在不知不觉中，出国留学将成为你一生中最有意义的经历之一。
          </span>



          <div>
            <span className={`${styles['text_14']} bold block text_bottom_24 widthAuto`}>五大秘诀让你在海外感受家的温暖</span>
          </div>



          <div className='dv_no_dot text_bottom_24'>
            <ul>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>1.迎接新体验：</strong>走出舒适圈，尝试新事物。只要有积极的态度，你就能发现新的环境所带来的的优势。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>2.参与各类活动：</strong>加入社团或俱乐部，结识志趣相投的朋友。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>3.与亲朋好友保持联系：</strong>与你思念的家人联系，同时也要积极面对新生活，结识新朋友。</span>
                </span>
                </div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>
                  <span className={`${styles['paragraph_1']} block heightAuto `}><strong>4.把握每一天：</strong>为自己设定容易实现的小目标，安排好每一天，你很快就能适应和熟悉新的环境。</span>
                </span>
                </div>
              </li>
            </ul>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block widthAuto`}>立即开启你的海外之旅</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>对出国留学的想法感到激动吗？迈向国际教育的下一步，看看智慧教育可以带你去向何方！</span>

          <div className='dv_card_box_content flex-row text_bottom_28'>
            <div className='dv_leftimg dv_imglogo-4'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>寻找适合你的大学</h4>
              <span className='text_photogry text_bottom_24'>从英国、美国或澳大利亚选择适合你的大学。</span>
              <div><a className='clickable dv_wenxin_button'>搜索大学</a></div>
            </div>
          </div>

          <div className='dv_card_box_content flex-row text_bottom_v29'>
            <div className='dv_leftimg dv_imglogo-5'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>寻找适合你的课程</h4>
              <span className='text_photogry text_bottom_24'>专为你量身定制的本科和研究生课程或英语语言课程。</span>
              <div><a className='clickable dv_wenxin_button'>搜索课程</a></div>
            </div>
          </div>








        </div>


      </div >
    );
  }
}
export default DDS_lanhupage_0;
