'use strict';

import React, { Component } from 'react';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_6']} flex-col indexResponse_box_6__2xis9`}>
        <div className={` flex-col  indexResponse_section_aus__2zolh`} />
        <span className={`${styles['text_13']} bold`}>留学澳大利亚</span>
        <div className="dv_mainbody_wrap_box">
          <span className={`${styles['paragraph_1']} block text_bottom_24 heightAuto`}>
            每一年，超过85万名国际学生选择留学澳大利亚。这些学生占澳大利亚学生总数的25%及以上，使得这里成为真正意义上的全球化和多元化的理想留学目的地。
          </span>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>排名一流大学</span><span className={`${styles['paragraph_1']}`}>——全球排名前100名中有9所澳大利亚大学（2025年QS世界大学排名）。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>学生满意度高</span><span className={`${styles['paragraph_1']}`}>——国际学生对他们在澳大利亚的留学生活和学习体验满意度评分达到近90%（2018年教育部国际学生调查）。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>就业率高</span><span className={`${styles['paragraph_1']}`}>——超过90%的澳大利亚大学毕业生在完成学位三年后获得高薪全职工作（2019年《大学世界新闻》）。</span>
          </div>
          <div>
            <span className={`${styles['paragraph_1']} bold`}>教育质量高</span><span className={`${styles['paragraph_1']}`}>——澳大利亚的高等教育体系享誉全球，在全球排名第8位（2019年Universitas 21全国教育体系排名）。</span>
          </div>


          <div>
            <span className={`${styles['text_14']} bold block text_bottom_24`}>体验新文化</span>
          </div>

          <span className={`${styles['paragraph_1']} text_bottom_24 block`}>
            从乌鲁鲁（艾尔斯岩）的自然美景到雄伟的大堡礁，澳大利亚记录着地球上最令人叹为观止的景观。90%的生物为澳大利亚独有，如：袋鼠、短尾矮袋鼠和考拉等有袋动物。<br></br>
            澳大利亚不仅是生物群栖息地和内陆地区，更是世界上拥有游客最多的家园，如：悉尼、墨尔本、布里斯班、珀斯、凯恩斯和阿德莱德。澳大利亚的城市是多元文化的代表，你可以在这里告别都市的喧嚣，享受生活的本质。
          </span>

          <div className='dv_card_box_content flex-row  text_bottom_24'>
            <div className='dv_leftimg dv_imglogo-1'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>适应海外生活</h4>
              <span className='text_photogry text_bottom_24'>搬至国外生活是一大步。让你更加轻松一点地过渡到澳大利亚的生活。</span>
              <div><a className='clickable dv_wenxin_button'>温馨提示</a></div>
            </div>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block`}>一生难忘的冒险</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>选择留学澳大利亚，意味着你将近距离的体验这个美丽的国度。你可能听说过悉尼及其著名的歌剧院，除此之外，在这片广阔的土地上还有许多其他地标建筑和风景等待你的探索。</span>
          <div className='dv_text_li_wrap text_bottom_24'>
            <ul>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']} bold`}>繁华的布里斯班</span><span className={`${styles['paragraph_1']}`}>——昆士兰的国际化首府城市，也是通往黄金海岸（Gold Coast）等澳大利亚最佳海滩的必经之地。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']} bold`}>前卫的墨尔本</span><span className={`${styles['paragraph_1']}`}>——维多利亚州的首府城市，已发展成为澳大利亚的时尚之都，拥有令人向往的当地艺术和音乐展。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']} bold`}>悠闲的珀斯</span><span className={`${styles['paragraph_1']}`}>——西澳大利亚州的首府，友好、多元而随性。珀斯是一座宝藏城市，这里的海滩、美食和自然风光都等待着你的发掘。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']} bold`}>奔放的阿德莱德</span><span className={`${styles['paragraph_1']}`}>——这座南澳大利亚州首府以其夜生活、节日和美食而闻名。这里的葡萄酒也名扬于世。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']} bold`}>低调的达尔文</span><span className={`${styles['paragraph_1']}`}>——澳大利亚最小的首府，达尔文也是澳大利亚最北端的城市。你可以在这里了解到该国民族文化的传承，以及最古老的现存文化。</span></div>
              </li>
            </ul>
          </div>

          <div className='dv_card_box_content flex-row text_bottom_24'>
            <div className='dv_leftimg dv_imglogo-2'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>学习一种全球语言</h4>
              <span className='text_photogry text_bottom_24'>从众人中脱颖而出。精通的英语技能将助力你的未来职业发展。</span>
              <div><a className='clickable dv_wenxin_button'>了解更多</a></div>

            </div>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block`}>你的未来</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>在澳大利亚学习，你不仅可以获得全球认可的学位，更受世界雇主的青睐。在当今竞争激烈的就业市场中脱颖而出：</span>
          <div className='dv_text_li_wrap text_bottom_24 dv_small_dot'>
            <ul>
              <li >
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>通过实习机会、工作经验和志愿活动等，增加你的行业知识和技能。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>通过社交活动、业内活动和大师班为未来建立人脉。</span></div>
              </li>
              <li>
                <div className='text_pargraphy'><span className={`${styles['paragraph_1']}`}>适应新的环境、文化和语言。</span></div>
              </li>


            </ul>
          </div>

          <span className={`${styles['text_14']} bold text_bottom_24 block widthAuto`}>即刻开启旅程，体验精彩人生</span>
          <span className={`${styles['paragraph_1']} block heightAuto text_bottom_24`}>对出国留学的想法感到激动吗？迈向国际教育的下一步，看看智慧教育可以带你去向何方！</span>

          <div className='dv_card_box_content flex-row  text_bottom_v29'>
            <div className='dv_leftimg dv_imglogo-2'></div>
            <div className='dv_right_text flex-col'>
              <h4 className='text_middle_title bold'>寻找适合你的课程</h4>
              <span className='text_photogry text_bottom_24'>专为你量身定制的本科和研究生课程或英语语言课程。</span>
              <div><a className='clickable dv_wenxin_button'>搜索课程</a></div>
            </div>
          </div>

        </div>
      </div >
    );
  }
}
export default DDS_lanhupage_0;
